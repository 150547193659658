<mat-form-field appearance="outline" class="w-100 sm" [ngClass]="extraClass">  
    <mat-label>{{ label | translate}}</mat-label> 
    <input #field type="text"
            placeholder="Min 3 simvol daxil edin"
            matInput
            [formControl]="control"
            [matAutocomplete]="auto"
            (blur)="onBlur()">
    <mat-icon matSuffix>search</mat-icon>  
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionsScroll)="onScroll()" (optionSelected)="onOptionSelected($event)" class="custom-autocomplete">
        <mat-option *ngFor="let item of list" [value]="item">
            {{item.name}} {{showpartnumber ? item.partNumber : ''}}
        </mat-option>
        <mat-option *ngIf="!list.length && noResults" value="" disabled="true">
            <b>No results found</b>
        </mat-option> 
    </mat-autocomplete>   
    <mat-error>{{ 'validation.required' | translate }}</mat-error>  
</mat-form-field>