import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { map } from 'rxjs/operators';
import { AppConfigService } from '../app.config.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService { 
  constructor(public environment: AppConfigService, 
              public http:HttpClient,
              public snackBar: MatSnackBar) { 
    super(environment, http);
  } 

  public get currentUser() { 
    return JSON.parse(localStorage.getItem('amet-current-user')!);
  }

  public login(credentials:any){
    return this.post('/auth/signin', credentials).pipe(map((res:any) => {
        if(res.status == true){ 
          let user = {
            "id": res.data.id,
            "fullName": res.data.fullName,
            "username": res.data.username,
            "roles": res.data.roles
          }; 
          localStorage.setItem('amet-token', res.data.token); 
          localStorage.setItem('amet-current-user', JSON.stringify(user)); 
          return res.data.token;           
        }
        else{  
          this.snackBar.open(res.ResponseException.ExceptionMessage, '×', {
            duration: 3000,
            verticalPosition: 'top'
          }); 
        }
    }));
  } 

  public logout() {  
    localStorage.removeItem('amet-token');
    localStorage.removeItem('amet-current-user');  
  } 

}
