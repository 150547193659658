import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumberDirective } from './only-number.directive'; 
import { MaskDateDirective } from './mask-date.directive';
import { PhoneMaskDirective } from './phone-mask.directive';
import { MaskEmailDirective } from './email-mask.directive';
import { MaskDateTimeDirective } from './mask-date-time.directive'; 
import { TimeMaskDirective } from './time-mask.directive';
import { OptionsScrollDirective } from './options-scroll.directive';

@NgModule({
  declarations: [
    OnlyNumberDirective,
    MaskDateDirective,
    PhoneMaskDirective,
    MaskEmailDirective,
    MaskDateTimeDirective,
    TimeMaskDirective,
    OptionsScrollDirective
  ],
  exports: [
    OnlyNumberDirective,
    MaskDateDirective,
    PhoneMaskDirective,
    MaskEmailDirective,
    MaskDateTimeDirective,
    TimeMaskDirective,
    OptionsScrollDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
