import { Component, Input, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormArray,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validators
} from "@angular/forms";
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-translation-panel',
  templateUrl: './translation-panel.component.html',
  styleUrls: ['./translation-panel.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TranslationPanelComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: TranslationPanelComponent, multi: true }
  ]
})
export class TranslationPanelComponent implements ControlValueAccessor {
  @ViewChild('trpanel') trpanel!: MatExpansionPanel; 
  disableAnimation = true;
  formArray: FormArray = new FormArray([]);
  _onChange:any;
  _onTouched:any;
  sub:Subscription = new Subscription;
  constructor() { }

  writeValue(translations: any[]): void {
    if(!translations.length){
      translations = [
        { id: null, name: null, languageId: 1 },
        { id: null, name: null, languageId: 2 },
        { id: null, name: null, languageId: 3 }
      ]
    }
    if(translations.length < 3){
      let az = translations.find(t => t.languageId == 1);
      if(!az){
        translations.push( { id: null, name: null, languageId: 1 } );
      }
      let en = translations.find(t => t.languageId == 2);
      if(!en){
        translations.push( { id: null, name: null, languageId: 2 } );
      }
      let ru = translations.find(t => t.languageId == 3);
      if(!ru){
        translations.push( { id: null, name: null, languageId: 3 } );
      }
    }
    this.formArray = new FormArray(
      translations
      .sort((a, b) => {
        return a.languageId - b.languageId;
      })
      .map((x:any) => {
        return new FormGroup({
          id: new FormControl(x.id),
          name: new FormControl(x.name),
          languageId: new FormControl(x.languageId)
        });
      })
    );
    (this.formArray.controls[0] as FormGroup).controls.name.setValidators([Validators.required]);
    (this.formArray.controls[0] as FormGroup).controls.name.markAsTouched();
    (this.formArray.controls[0] as FormGroup).controls.name.updateValueAndValidity();
    this.sub = this.formArray.valueChanges.subscribe(res => {
      this._onChange(res);
    });
  } 

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn:(value: any) => void) { 
    this._onTouched = fn; 
  }
 

  validate({ value }: FormControl) {
    return !this.formArray || this.formArray.valid
      ? null
      : { error: "Some fields are not fullfilled" };
  }

  getLabel(languageId:number){ 
    if(languageId == 1){
      return 'AZ';
    }
    else if(languageId == 2){
      return 'EN'
    }
    else{
      return 'RU'
    }
  }

  ngAfterViewInit(){
    setTimeout(() => this.disableAnimation = false); 
    setTimeout(() => {
      if(!(this.formArray.controls[0] as FormGroup).controls.name.valid){ 
        this.trpanel.expanded = true;
      }
    });
  }

  ngOnDestroy(){ 
    this.sub.unsubscribe();
  }

}
