<mat-form-field appearance="outline" class="w-100 sm" [ngClass]="extraClass">
    <mat-label>{{label}}</mat-label>  
    <mat-select #select [formControl]="control" panelClass="search-dropdown" (selectionChange)="selectionChanged($event)" (openedChange)="openedChanged($event)">
        <input matInput placeholder="Axtar..." class="search-input" (keyup)="searchList($event.target)" (keydown)="$event.stopPropagation()" autocomplete="off" id="search-input">
        <div class="search-container">
            <mat-option [value]="">-</mat-option> 
            <mat-option *ngFor="let item of selectedList" [value]="item.id">
                {{item[property]}}
            </mat-option> 
            <mat-option *ngIf="list && list.length < 2 && hasSpinner">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-spinner diameter="32"></mat-spinner>
                </div> 
            </mat-option>  
        </div> 
    </mat-select>
    <mat-error>Xana vacibdir</mat-error>
</mat-form-field> 