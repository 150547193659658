<mat-toolbar class="admin-toolbar mat-elevation-z2" color="primary">
    
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <div fxLayout="row" fxLayoutAlign="start center" > 
            <a routerLink="/" (click)="closeSubMenus()" class="logo"> 
                <img src="assets/img/logo.png" alt="logo">
            </a>
            <button mat-icon-button (click)="toggleSidenav()">
                <mat-icon>menu</mat-icon>
            </button> 
        </div>
        <!-- <div>
            <a mat-icon-button routerLink="/" [matTooltip]="'menu.home' | translate" matTooltipPosition="below">
                <mat-icon>home</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/" [matTooltip]="'menu.products' | translate" matTooltipPosition="below">
                <mat-icon>category</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/" [matTooltip]="'menu.companies' | translate" matTooltipPosition="below">
                <mat-icon>business</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/" [matTooltip]="'menu.users' | translate" matTooltipPosition="below">
                <mat-icon>assignment_ind</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/" [matTooltip]="'menu.warehouse' | translate" matTooltipPosition="below">
                <mat-icon>store</mat-icon>
            </a>
            <a mat-icon-button routerLink="/" [matTooltip]="'menu.codes' | translate" matTooltipPosition="below">
                <mat-icon>qr_code_2</mat-icon>
            </a>
            <a mat-icon-button routerLink="/" [matTooltip]="'menu.dictionaries' | translate" matTooltipPosition="below">
                <mat-icon>list_view</mat-icon>
            </a>
            <a mat-icon-button routerLink="/" [matTooltip]="'menu.langs' | translate" matTooltipPosition="below">
                <mat-icon>language</mat-icon>
            </a> 
        </div> -->


        <!-- <div>
            <a mat-icon-button routerLink="/" matTooltip="Əsas səhifə" matTooltipPosition="below">
                <mat-icon color="primary">home</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/" matTooltip="Əməliyyatlar" matTooltipPosition="below">
                <mat-icon color="primary">sticky_note_2</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/" matTooltip="Maliyyə" matTooltipPosition="below">
                <mat-icon color="primary">receipt</mat-icon>
            </a>  
            <a mat-icon-button routerLink="/" matTooltip="Logistika" matTooltipPosition="below">
                <mat-icon color="primary">commute</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/" matTooltip="Satış" matTooltipPosition="below">
                <mat-icon color="primary">analytics</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/customers" matTooltip="Müştərilər" matTooltipPosition="below">
                <mat-icon color="primary">people</mat-icon>
            </a> 
            <a mat-icon-button routerLink="/" matTooltip="Anbar" matTooltipPosition="below">
                <mat-icon color="primary">store</mat-icon>
            </a>
            <a mat-icon-button routerLink="/" matTooltip="İşçilər" matTooltipPosition="below">
                <mat-icon color="primary">assignment_ind</mat-icon>
            </a>
            <a mat-icon-button routerLink="/" matTooltip="Sazlamalar" matTooltipPosition="below">
                <mat-icon color="primary">settings</mat-icon>
            </a>
        </div> -->
        <div>  
            <span class="fullname">{{authService.currentUser?.fullName}}</span> 
            <a mat-icon-button routerLink="/login">
                <mat-icon>power_settings_new</mat-icon>
            </a> 
        </div>
    </div>

    
</mat-toolbar>
  
  <mat-drawer-container class="admin-container"> 

    <mat-drawer #sidenav [opened]="true" [mode]=" 'side' " autoFocus="false" class="mat-elevation-z2"> 
        <perfect-scrollbar> 
            <!-- <a mat-button routerLink="/test">Test</a>    -->
            <app-menu [menuItems]="menuItems" [menuParentId]="0"></app-menu> 
            <!-- <p>start</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid praesentium molestiae perspiciatis quis numquam fuga? Odit, cumque nulla. Quas, deserunt reiciendis? Cupiditate molestias debitis earum veniam eius rem animi quasi.</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid praesentium molestiae perspiciatis quis numquam fuga? Odit, cumque nulla. Quas, deserunt reiciendis? Cupiditate molestias debitis earum veniam eius rem animi quasi.</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid praesentium molestiae perspiciatis quis numquam fuga? Odit, cumque nulla. Quas, deserunt reiciendis? Cupiditate molestias debitis earum veniam eius rem animi quasi.</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid praesentium molestiae perspiciatis quis numquam fuga? Odit, cumque nulla. Quas, deserunt reiciendis? Cupiditate molestias debitis earum veniam eius rem animi quasi.</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid praesentium molestiae perspiciatis quis numquam fuga? Odit, cumque nulla. Quas, deserunt reiciendis? Cupiditate molestias debitis earum veniam eius rem animi quasi.</p>
            <p>end</p> -->
        </perfect-scrollbar>
    </mat-drawer> 
    <mat-drawer-content>  
        <app-breadcrumb></app-breadcrumb>  
        <router-outlet></router-outlet>  
        <router-outlet name="aux"></router-outlet>  
    </mat-drawer-content> 
  
   
    
  </mat-drawer-container>
