import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin';
  constructor( public translate: TranslateService){     
    translate.addLangs(['az', 'en', 'ru']);
    if(localStorage.getItem('amet-lang')){
      translate.setDefaultLang(localStorage.getItem('amet-lang')!); 
      translate.use(localStorage.getItem('amet-lang')!);
    }
    else{
      translate.setDefaultLang('az'); 
      translate.use('az');
    } 
  }
}
