<button mat-icon-button type="button" (click)="trpanel.expanded = !trpanel.expanded;" class="translation-btn">
    <mat-icon [class]="(trpanel.expanded) ? 'primary-color' : 'text-muted'">{{ (trpanel.expanded) ? 'close' : 'language' }}</mat-icon>
</button> 
<mat-icon *ngIf="trpanel.expanded" class="text-muted translation-panel-arrow">north</mat-icon>
<mat-expansion-panel [@.disabled]="disableAnimation" hideToggle #trpanel="matExpansionPanel" class="translation-panel" [class.mb-4]="trpanel.expanded">
    <div fxLayout="row wrap" class="pt-3 flex-wrapper-x">
        <div *ngFor="let group of formArray.controls; let last = last;" [formGroup]="$any(group)" fxFlex="100">
            <mat-form-field appearance="outline" class="w-100 sm" [class.pb-0]="last"> 
                <mat-label>{{getLabel($any(group).controls.languageId.value)}}</mat-label>
                <input matInput formControlName="name" (blur)="_onTouched()"/> 
                <mat-error *ngIf="$any(group).controls.name.errors?.required">{{ 'validation.required' | translate }}</mat-error> 
            </mat-form-field>  
        </div>  
    </div>   
</mat-expansion-panel> 
<!-- https://stackblitz.com/edit/angular-nzcygf?file=src%2Fapp%2Fhello.component.ts -->