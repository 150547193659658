<div fxLayout="row wrap" cdkDropListGroup class="flex-wrapper">
    <div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="i" *ngFor="let file of files; let i = index;" [fxFlex]="onlyOneFile ? 'initial' : 25" class="p-2">
      <div cdkDrag [cdkDragData]="i" (cdkDragEntered)="entered($event)">
        <div class="custom-placeholder" *cdkDragPlaceholder></div>  
        <div class="image-wrapper mat-elevation-z2" [class.only-one-file]="onlyOneFile">  

            <!-- <img *ngIf="file.extension == 'pdf'" src="assets/images/pdf.png" alt="pdf">
            <img *ngIf="file.extension == 'rtf'" src="assets/images/rtf.png" alt="pdf">
            <img *ngIf="['doc','docx'].includes(file.extension)" src="assets/images/docx.png" alt="docx">
            <img *ngIf="['xls','xlsx'].includes(file.extension)" src="assets/images/excel.png" alt="excel">
            <img *ngIf="['png','jpeg','jpg'].includes(file.extension)" [src]="file.content" alt="skan">  -->
            
            <img *ngIf="file.extension == 'pdf'" src="assets/img/pdf.png" alt="pdf">
            <!-- <img *ngIf="['png','jpeg','jpg','gif'].includes(file.extension)" [src]="(file.base64) ? file.origBase64 : '/file/getImageById/'+file.id | getUrl" [attr.alt]="'image-'+file.id">   -->
            <img *ngIf="['png','jpeg','jpg','gif'].includes(file.extension)" [src]="(file.base64) ? file.origBase64 : '/file/getFileByPath?path='+file.path | getUrl" [attr.alt]="'image-'+file.id">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="btn-wrapper p-1">  
                <button *ngIf="canDownload" mat-mini-fab color="primary" type="button" (click)="downloadFile(file)" matTooltip="Yüklə" matTooltipPosition="below">
                    <mat-icon>cloud_download</mat-icon>
                </button>
                <button mat-mini-fab color="primary" type="button" (click)="openInNewTab(file)" matTooltip="Böyüt">
                    <mat-icon>zoom_in</mat-icon>
                </button> 
                <button *ngIf="canDelete" mat-mini-fab color="warn" type="button" (click)="delete(file)" matTooltip="Sil">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div> 
        </div>  
      </div>
    </div>
  </div>
<div *ngIf="canUpload" class="file-upload mt-3"> 
    <div class="p-relative">
        <input *ngIf="allowMultiple" id="multipleFileUploader" type="file" [accept]="acceptTypes" (change)="fileChange(input)" #input multiple/>
        <input *ngIf="!allowMultiple" id="singleFileUploader" type="file" [accept]="acceptTypes" (change)="fileChange(input)" #input/>
    </div> 
    <button mat-raised-button color="primary" class="theme-btn" type="button" (click)="fileUploadClick()">Əlavə et</button>
</div>  