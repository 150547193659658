import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AppSettings, Settings } from '../../../app.settings'; 
import { Menu } from './menu.model';
import { MenuService } from './menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class MenuComponent implements OnInit {
  @Input('menuItems') menuItems:Menu[] = [];
  @Input('menuParentId') menuParentId = 0;
  parentMenu:Array<any> = [];
  public settings: Settings;
  constructor(public appSettings:AppSettings, public menuService:MenuService) { 
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {  
    this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId); 
    if(this.parentMenu.length === 4 && this.menuParentId === 30) {
      this.parentMenu.push({id: 26, title: 'Reservers', routerLink: '/order-operations/reservers', href: null, icon: 'arrow_right', })
      this.parentMenu.push({id: 27, title: 'Sifarişlər', routerLink: '/order-operations/books', href: null, icon: 'arrow_right', })
      this.parentMenu.push({id: 28, title: 'Təkliflər', routerLink: '/order-operations/offers', href: null, icon: 'arrow_right', })
    }
  }

  onClick(menuId:number){
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);    
  }

}
