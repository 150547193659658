import { Component, ElementRef, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlName, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Observable, Subject } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { CommonService } from '../../services/common.service';
import { BaseResponse } from '../../models/base-response';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ChipsAutocompleteComponent
    }
  ] 
})
export class ChipsAutocompleteComponent implements ControlValueAccessor, OnInit {
  @ViewChild('field', { static: true }) field: any;
 // @ViewChild('field', { static: true }) field!: MatInput;
  @ViewChild('auto') matAutocomplete!: MatAutocomplete;
  @Input('isDisable') isDisable:boolean = false;
  @Input('label') label:string = '';
  @Input('url') url = '';
  public control:FormControl = new FormControl; 
  separatorKeysCodes: number[] = [ENTER, COMMA];  
  public searchText:string = ''; 
  public pageIndex:number = 0;
  public pageSize:number = 10; 
  public complete:boolean = false;   
  public items: any[] = [];
  public allItems:any[] = []; 
  public noResults:boolean = false;
  public modelChanged = new Subject<any>();

  @Input()
  get errorStateMatcher(): ErrorStateMatcher {
      return this.field.errorStateMatcher;
  }
  set errorStateMatcher(val) {
      this.field.errorStateMatcher = val;
  }

  @Input()
  get placeholder() {
      return this.field.placeholder;
  }
  set placeholder(plh) {
      this.field.placeholder = plh;
  }

  @Input()
  get value() {
      return this.field.value;
  }
  set value(val) {
      this.field.value = val;
  }
  
  constructor(@Optional() @Self() ngControl: NgControl, @Optional() private _controlName: FormControlName, public commonService:CommonService) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    } 
   
  } 

  ngOnInit(): void {
    this.control = this._controlName.control;
    (this.isDisable) ? this.control.disable() : this.control.enable();  
    // console.log('this.control', this.control); 
    
    if(this.control.value && this.control.value.length){ 
      this.items = this.control.value;
    }
    
    this.modelChanged.pipe(debounceTime(500)).subscribe(() => { 
      if(this.searchText){ 
        this.complete = false; 
        this.allItems = [];
        if(this.searchText.length >= 3){
          this.getList();
        }
      }
    });
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
   //this.field.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    //this.field.registerOnTouched(fn);
  } 

  
  public onChangeInput(event:any){ 
    this.searchText = event;
    this.modelChanged.next();  
  }

  public getList(){
    let params:{[k: string]: any} = { 
      name: this.searchText,
      skip: this.pageIndex*this.pageSize,
      limit: this.pageSize
    };  
    this.commonService.getByParams(this.url, params).subscribe((res:BaseResponse)=>{  
      if(res.status){ 
        (!res.data.list.length) ? this.noResults = true : this.noResults = false;
        this.allItems.push(...res.data.list); 
        if(res.data.list.length < this.pageSize){ 
          this.complete = true;
        }
      }  
    });
  }

  public onScroll(){   
    if(!this.complete){ 
      this.pageIndex++;
      this.getList();
    } 
  } 

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value; 
    if ((value || '').trim()) { 
     this.items.push(value)
    } 
    if (input) {
      input.value = '';
    } 
    this.control.setValue(null);
  }

  remove(item: any): void {
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {  
    this.items.push(event.option.value);
    this.field.nativeElement.value = '';
    this.searchText = ''; 
    this.control.setValue(this.items);
  } 

}
