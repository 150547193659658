import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[onlyNumber]'
})
export class OnlyNumberDirective {
  
    @Input() allows = {
      dot: false,
      zero: false,
      area: false,
      multipleZero: false
    }  
    private regex!: RegExp;  
    constructor(private el: ElementRef) { }

    @HostListener('keypress', [ '$event' ]) onKeyPress(event:any) { 
      let { x, y, ...z } = { x: 1, y: 2, a: 3, b: 4 };
        if(this.allows.dot){ 
          if(this.allows.area){
            this.regex = new RegExp(/^(?!0[0-9])[0-9]+(\.\d{0,8}?){0,1}$/g);
          }
          else{
            this.regex = new RegExp(/^(?!0[0-9])[0-9]+(\.\d{0,2}?){0,1}$/g);
          } 
        }
        else{
          this.regex = new RegExp(/^(?!0[0-9])[0-9]+([0-9]*){0,1}$/g); 
        }
        if(!this.allows.zero){
          if(!this.allows.multipleZero){
            if(event.target.value.length == 0 && event.which == 48 ){
              return false;
            }
          }
        }  
        // if(this.allows.multipleZero){
        //   this.regex = new RegExp(/^[0-9]*$/g);  
        // } 

        let current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join(''); 
        if (next && !String(next).match(this.regex)) {
          event.preventDefault();
        }
        return true;
    }
}