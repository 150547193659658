import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'; 
import { CommonService } from '../../services/common.service';
import { UtilsService } from '../../services/utils.service';
import { GetUrlPipe } from '../../pipes/get-url.pipe';
import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [GetUrlPipe]
})
export class FileUploadComponent implements OnInit {
  @Input('editMode') editMode:boolean = false;
  @Input('attachments') attachments:any[] = [];
  @Input('canUpload') canUpload:boolean = true;
  @Input('canDelete') canDelete:boolean = true;
  @Input('canDownload') canDownload:boolean = true; 
  @Input('fileSize') fileSize = 1500;
  @Input('fileLimit') fileLimit = 100;
  @Input('allowMultiple') allowMultiple:boolean = false;
  @Input('acceptTypes') acceptTypes:any; 
  @Input('onlyOneFile') onlyOneFile:boolean = false; 
  @Output() onFileChange: EventEmitter<any> = new EventEmitter(); 
  @Output() onFileUploadClick: EventEmitter<any> = new EventEmitter();
  @Output() onFileDelete: EventEmitter<any> = new EventEmitter();
  public files:any[] = []; 
  public deleteFilesIds:any[] = [];  

  constructor(public commonService:CommonService, public utilsService: UtilsService, private getUrl: GetUrlPipe) { } 

  ngOnInit(): void {
    console.log('this.attachments on file upload component: ', this.attachments)
    if(this.attachments && this.attachments.length){ 
      this.attachments.sort((a, b) => {
        return a.orderNumber - b.orderNumber;
      });
      this.attachments.forEach((file:any) => { 
        file.extension = (file.path) ? file.path.split('.').pop().toLowerCase() : 'png';
        this.files.push(file); 
      });  
    }   
  }   

  public fileChange(input:any){ 
    console.log('fileChange: ', input) 
    if(input.files.length){ 
      for (var i = 0; i < input.files.length; i++){
        const reader = new FileReader();
        if (input.files[i].size / 1024 > this.fileSize) {  
          let message = this.utilsService.getTranslateValue('message.file-size', this.fileSize.toString());
          let dialogRef = this.utilsService.openAlertDialog(message!);  
          dialogRef.afterClosed().subscribe(() => {
            this.clearInput();
          });  
        } 
        else {   
          let fileName = input.files[i].name;
          let size = input.files[i].size; 
          let extension = input.files[i].name.split('.').pop(); 
          reader.readAsDataURL(input.files[i]);
          reader.onload = () => { 
            if(this.files.length == this.fileLimit){
              let message = this.fileLimit + ' fayldan artiq fayl yükləyə bilməzsiniz';
              let dialogRef = this.utilsService.openAlertDialog(message!);  
              dialogRef.afterClosed().subscribe(() => {
                return false;
              });  
            }  
            if(this.onlyOneFile){
              this.files.length = 0;
            }
            this.files.push({ 
              "fileName": fileName,  
              "base64": this.subsBase64(reader.result, extension),
              "extension": extension,
              "size": size,
              "origBase64": reader.result,
              "orderNumber": this.files.length + 1
            }); 
            this.onFileChange.emit(this.files);  
          }
        }  
      }
    }  
  }

  public subsBase64(base64:any, extension:string){  
    let contentType = 'image/png';  
    if(extension == 'png'){
      contentType = 'image/png';
    } 
    else if(extension == 'pdf'){
      contentType = 'application/pdf';
    } 
    else{
      contentType = 'image/jpeg';
    }
    return base64.substr(`data:${contentType};base64,`.length);  
  }
   
  public downloadFile(file:any){   
    if (file.base64) {
      this.downloadBase64(file.origBase64, file.fileName);
    } else {  
      // this.downloadFromSrc(this.getUrl.transform('/file/getImageById/'+file.id) as any);  
      this.downloadFromSrc(this.getUrl.transform('/file/getFileByPath?path='+file.path) as any, file.extension);
    }  
  } 

  private downloadBase64(base64:any, fileName: any){ 
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = base64; 
    a.download = fileName;
    document.body.appendChild(a);
    a.click();   
    window.URL.revokeObjectURL(base64); 
    a.remove(); 
  }

  async downloadFromSrc(fileSrc:any, extension:string) { 
    const file = await fetch(fileSrc);
    const fileBlob = await file.blob(); 
    let contentType = this.getContentType(extension);
    var newBlob = new Blob([fileBlob], {type: contentType});
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(newBlob);
      return;
    }  
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = Date.now().toString();
    link.click();
    setTimeout(function(){ 
      window.URL.revokeObjectURL(data);
    }, 100);   
  }
  
  public delete(file:any) { 
    const message = this.utilsService.getTranslateValue('message.sure-delete');
    let dialogRef = this.utilsService.openConfirmDialog('', message!);
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){ 
        const index: number = this.files.indexOf(file); 
        this.files.splice(index, 1);          
        this.onFileChange.emit(this.files);
        this.clearInput();
        if(this.editMode){
          this.deleteFilesIds.push(file.id);
          this.onFileDelete.emit(this.deleteFilesIds);
        } 
      }
    });   
  } 

  public fileUploadClick(){ 
    this.onFileUploadClick.emit();
  }

  public clearInput(){
    if(this.files.length == 0){ 
      if(document.getElementById('multipleFileUploader')){ 
        (<HTMLInputElement>document.getElementById('multipleFileUploader')).value = ''; 
      }
      if(document.getElementById('singleFileUploader')){ 
        (<HTMLInputElement>document.getElementById('singleFileUploader')).value = ''; 
      }
    }  
  }  

  public async openInNewTab(file:any){ 
    console.log(file) 
    let contentType = this.getContentType(file.extension); 
    if (file.base64) {
      // const byteCharacters = atob(file.base64.substr(`data:${contentType};base64,`.length));
      const byteCharacters = atob(file.base64);
      const byteArrays = [];    
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }    
        const byteArray = new Uint8Array(byteNumbers);    
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: contentType});
      const blobUrl = URL.createObjectURL(blob);    
      window.open(blobUrl, '_blank'); 
    }
    else {
      const newFile = await fetch(this.getUrl.transform('/file/getFileByPath?path='+file.path) as any);
      const fileBlob = await newFile.blob(); 
      const blob = new Blob([fileBlob], {type: contentType}); 
      const blobUrl = URL.createObjectURL(blob);    
      window.open(blobUrl, '_blank'); 
    } 
  }    

  public entered(event: CdkDragEnter) {
    moveItemInArray(this.files, event.item.data, event.container.data); 
    let i = 0;
    this.files.forEach((file:any)=>{
      file.orderNumber = i;
      i++;
    }); 
    this.onFileChange.emit(this.files);
  }

  public getContentType(extension:string){
    let contentType = 'image/png';
    if(extension == 'pdf'){
      contentType = 'application/pdf'
    } 
    else if(extension == 'doc'){
      contentType = 'application/msword';
    } 
    else if(extension == 'docx'){
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } 
    else if(extension == 'xls'){
      contentType = 'application/vnd.ms-excel';
    } 
    else if(extension == 'xlsx'){
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } 
    else if(extension == 'rtf'){
      contentType = 'application/rtf';
    }
    else if(extension == 'png'){
      contentType = 'image/png';
    } 
    else{
      contentType = 'image/jpeg';
    }
    return contentType;
  } 

}  