import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListFilterPipe } from './list-filter.pipe';
import { GetUrlPipe } from './get-url.pipe'; 

@NgModule({
  declarations: [
    ListFilterPipe,
    GetUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ListFilterPipe,
    GetUrlPipe
  ]
})
export class PipesModule { }
