import { Component, HostListener, OnInit, ViewChild } from '@angular/core'; 
import { AuthService } from '../services/auth.service'; 
import { MenuService } from '../theme/components/menu/menu.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  @ViewChild('sidenav') sidenav:any; 
  public menuItems:any[] = [];
  constructor(public authService:AuthService, public menuService:MenuService) { }

  ngOnInit(): void {
    this.menuItems = this.menuService.getMenuItems();
  }

  ngAfterViewInit(){
    if(window.innerWidth <= 960){ 
      setTimeout(() => {
        this.sidenav.close();
      }); 
    }
    this.menuService.expandActiveSubMenu(this.menuService.getMenuItems()); 
  }

  @HostListener('window:resize')
  public onWindowResize():void {
    if(window.innerWidth <= 960){
      this.sidenav.close();
    }
    else{ 
      this.sidenav.open();
    }
  }

  public toggleSidenav(){
    this.sidenav.toggle();
  }

  public closeSubMenus(){
    this.menuService.closeAllSubMenus();     
  }  

}
