<div fxLayout="row" fxLayoutAlign="center center" class="h-100">
    <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
        <mat-card class="p-0 mat-elevation-z6 o-hidden">
            <div fxLayout="column" fxLayoutAlign="center center" class="bg-primary py-4 default-bg">
                <mat-icon class="mat-icon-xlg">error</mat-icon>
                <h1 class="error">404</h1>
            </div>
            <mat-card-content fxLayout="column" fxLayoutAlign="end center"> 
                <h2 class="fw-500 py-3">{{ 'not-found' | translate }}</h2> 
                <a routerLink="/" mat-raised-button color="primary" class="mat-elevation-z8 mb-4">
                    <mat-icon>home</mat-icon>
                    <span class="ml-1">{{ 'menu.home' | translate }}</span>
                </a>  
            </mat-card-content>
        </mat-card>          
    </div>
</div> 
