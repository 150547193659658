import { Menu } from './menu.model';  

export const menuItems = [ 
    new Menu (1, 'menu.home', '/', null, 'home', null, false, 0),

    new Menu (10, 'menu.organization-structure', null, null, 'business', null, true, 0),
    new Menu (11, 'menu.organization', '/organization-structure/organization', null, 'arrow_right', null, false, 10),
    new Menu (12, 'menu.department', '/organization-structure/department', null, 'arrow_right', null, false, 10),
    new Menu (13, 'menu.department-positions', '/organization-structure/department-positions', null, 'arrow_right', null, false, 10),

    new Menu (20, 'menu.product-operations', null, null, 'category', null, true, 0),
    new Menu (21, 'menu.products', '/product-operations/products', null, 'arrow_right', null, false, 20),
    new Menu (22, 'menu.equipment', '/product-operations/equipment', null, 'arrow_right', null, false, 20),
    new Menu (23, 'menu.receipts', '/product-operations/receipts', null, 'arrow_right', null, false, 20),
    new Menu (24, 'menu.stock-income', '/product-operations/stock-income', null, 'arrow_right', null, false, 20), 
    new Menu (25, 'menu.alternative-groups', '/product-operations/alternative-groups', null, 'arrow_right', null, false, 20), 

    new Menu (30, 'menu.order-operations', null, null, 'dvr', null, true, 0),
    new Menu (31, 'menu.cashier', '/order-operations/cashier', null, 'arrow_right', null, false, 30),
    new Menu (32, 'menu.order', 'order', null, 'arrow_right', null, false, 30),
    new Menu (33, 'menu.orders', '/order-operations/orders', null, 'arrow_right', null, false, 30), 
    new Menu (34, 'menu.refunds', '/order-operations/refunds', null, 'arrow_right', null, false, 30),  

    new Menu (40, 'menu.users', '/users', null, 'assignment_ind', null, false, 0),
    new Menu (50, 'menu.persons', '/persons', null, 'people', null, false, 0),
    new Menu (60, 'menu.casing-operation', '/casing-operation', null, 'monetization_on', null, false, 0), 
    
    new Menu (70, 'menu.dictionaries', null, null, 'list_view', null, true, 0),
    new Menu (71, 'menu.brand', '/dictionaries/brand', null, 'arrow_right', null, false, 70),
    new Menu (72, 'menu.vehicle-make', '/dictionaries/vehicle-make', null, 'arrow_right', null, false, 70),
    new Menu (73, 'menu.vehicle-model', '/dictionaries/vehicle-model', null, 'arrow_right', null, false, 70), 
    new Menu (74, 'menu.vehicle-model-version', '/dictionaries/vehicle-model-version', null, 'arrow_right', null, false, 70), 
    new Menu (75, 'menu.stock', '/dictionaries/stock', null, 'arrow_right', null, false, 70),
    new Menu (76, 'menu.shelf', '/dictionaries/shelf', null, 'arrow_right', null, false, 70),
    new Menu (77, 'menu.category', '/dictionaries/category', null, 'arrow_right', null, false, 70),
    new Menu (78, 'menu.sub-category', '/dictionaries/sub-category', null, 'arrow_right', null, false, 70),
    new Menu (79, 'menu.sub-category-field', '/dictionaries/sub-category-field', null, 'arrow_right', null, false, 70), 
    new Menu (80, 'menu.tax-code', '/dictionaries/tax-code', null, 'arrow_right', null, false, 70),
   // new Menu (89, 'menu.countries', '/dictionaries/countries', null, 'arrow_right', null, false, 70), 

    new Menu (90, 'menu.website', null, null, 'public', null, true, 0),
    new Menu (91, 'menu.equipments', '/website/equipments', null, 'arrow_right', null, false, 90),
    new Menu (92, 'menu.news', '/website/news', null, 'arrow_right', null, false, 90),
    new Menu (93, 'menu.downloads', '/website/downloads', null, 'arrow_right', null, false, 90), 
    new Menu (94, 'menu.settings', '/website/settings', null, 'arrow_right', null, false, 90),  
    new Menu (95, 'menu.slides', '/website/slides', null, 'arrow_right', null, false, 90),  

    new Menu (110, 'menu.reports', null, null, 'summarize', null, true, 0),
    new Menu (111, 'menu.stock-residue', '/reports/stock-residue', null, 'arrow_right', null, false, 110),
    new Menu (112, 'menu.stock-residue-second', '/reports/stock-residue-second', null, 'arrow_right', null, false, 110),
    new Menu (113, 'menu.spare-parts-process', '/reports/spare-parts-process', null, 'arrow_right', null, false, 110),

    new Menu (150, 'menu.langs', '/langs', null, 'language', null, false, 0),
 
]