import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';
import { OrderGuard } from './guards/order.guard';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';

const routes: Routes = [
  { 
    path: '', 
    canActivate: [AuthenticationGuard],
    component: PagesComponent, children: [
      { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'menu.home' } },
      { path: 'organization-structure', loadChildren: () => import('./pages/organization-structure/organization-structure.module').then(m => m.OrganizationStructureModule), data: { breadcrumb: 'menu.organization-structure' } },
      { path: 'test', loadChildren: () => import('./pages/test/test.module').then(m => m.TestModule), data: { breadcrumb: 'Test' } },
      { path: 'customers', loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule), data: { breadcrumb: 'Customers' } },
      { path: 'dictionaries', loadChildren: () => import('./pages/dictionaries/dictionaries.module').then(m => m.DictionariesModule), data: { breadcrumb: 'menu.dictionaries' } },
      { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'menu.users' } },
      { path: 'persons', loadChildren: () => import('./pages/persons/persons.module').then(m => m.PersonsModule), data: { breadcrumb: 'menu.persons' } },
      { path: 'product-operations', loadChildren: () => import('./pages/product-operations/product-operations.module').then(m => m.ProductOperationsModule), data: { breadcrumb: 'menu.product-operations' } },
      { path: 'product-operations/equipment', loadChildren: () => import('./pages/product-operations/equipment/equipment.module').then(m => m.ProductOperationsModule), data: { breadcrumb: 'menu.equipment' } },
     
      { path: 'order-operations', loadChildren: () => import('./pages/order-operations/order-operations.module').then(m => m.OrderOperationsModule), data: { breadcrumb: 'menu.order-operations' } },
      { path: 'website', loadChildren: () => import('./pages/website/website.module').then(m => m.WebsiteModule), data: { breadcrumb: 'menu.website' } },
      { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule), data: { breadcrumb: 'menu.reports' } },
      { path: 'casing-operation', loadChildren: () => import('./pages/casing-operation/casing-operation.module').then(m => m.CasingOperationModule), data: { breadcrumb: 'menu.casing-operation' } },
      { path: 'order-operations/books/new-books', loadChildren: () => import('./pages/order-operations/new-books/new-books.module').then(m => m.NewBooksModule), data: { breadcrumb: 'Test' } },
      { path: 'order-operations/order/:id', loadChildren: () => import('./pages/order-operations/get-price-for-books/get-price-books.module').then(m => m.GetPriceBooksModule), data: { breadcrumb: 'Təklif elavə et' } },
    ]
  },
  { path: 'order', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule),  canActivate: [OrderGuard] },  
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) }, 
  { path: 'product-detail', loadChildren: () => import ('./pages/product-detail/product-detail.module').then(m => m.ProductDetailModule) },
  { path: '**', component: NotFoundComponent } 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
      // useHash: true
    })
  ],
  exports: [
      RouterModule
  ]
})
export class AppRoutingModule { } 
