import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
//import { ServerSideErrorsComponent } from './pages/errors/server-side-errors/server-side-errors.component';
//import { ResponseExceptionComponent } from './pages/errors/response-exception/response-exception.component';
import { AppSettings } from './app.settings';
//import { UnauthorizedComponent } from './pages/errors/unauthorized/unauthorized.component';

@Injectable()
export class AppInterceptor implements HttpInterceptor { 
    constructor(public snackBar: MatSnackBar, public router: Router, private dialogRef: MatDialog, public appSettings:AppSettings) { }
  
    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
        setTimeout(() => {
          this.appSettings.settings.disableBtn = true;  
        });
     
        // Disable loading bar when notifications api calls
        // const ngProgress = document.getElementsByTagName('ng-progress') as HTMLCollectionOf<HTMLElement>;
        // if (ngProgress[0]) {
        //   if (req.url.includes('notary/EAttorney/GetNewAttorneyCount') || req.url.includes('auth/Account/GetUserBalance')) {
        //     ngProgress[0].style['display'] = 'none';
        //   }
        //   else {
        //     ngProgress[0].style['display'] = ''; 
        //   }
        // }
       
        req = req.clone({
          setHeaders: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('amet-token') ? "Bearer " + localStorage.getItem('amet-token') : ""
          }
        });   
        
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {   
            //  console.log('interceptor response: ', event);
              
            setTimeout(() => {
              this.appSettings.settings.disableBtn = false;  
            });
           

            // if (new RegExp(['GetNotaryActionTable'].join("|")).test(event.url)) { 
            //   this.appSettings.settings.disableBtn = false;  
            // }

            if(event.body.status){
              if(!event.url?.includes('address-type')){
                if(event.url?.includes('/add') || event.url?.includes('/edit') || event.url?.includes('/delete') ){
                  this.snackBar.open(event.body.message, '×', {
                    duration: 3000,
                    verticalPosition: 'top',
                    horizontalPosition: 'center'
                  }); 
                }
              } 
              
            }


            // if(event.body.IsSuccess == false){
            //   if(event.body.StatusCode == 3131){ 
            //     this.dialogRef.closeAll();
            //     this.router.navigate(['/login']);
            //     // this.snackBar.openFromComponent(UnauthorizedComponent, {
            //     //   data: event.body.ResponseException.ExceptionMessage,
            //     //   duration: 500000,
            //     //   verticalPosition: 'top' 
            //     // }); 
            //   }
            //   else if(event.body.StatusCode == 3003){
            //     // this.snackBar.openFromComponent(ServerSideErrorsComponent, {
            //     //   data: event.body.ResponseException,
            //     //   duration: 5000,
            //     //   verticalPosition: 'top'
            //     // });
            //   }
            //   else{                
            //     // if (!req.url.includes('notary/Integration/GetFormOnePdf')) {
            //     //   this.snackBar.openFromComponent(ServerSideErrorsComponent, {
            //     //     data: event.body.ResponseException,
            //     //     duration: 30000,
            //     //     verticalPosition: 'top'
            //     //   });
            //     // }
            //   } 
            // }



          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {           
            this.appSettings.settings.disableBtn = false;  
            console.log('=================')
            console.log(err)
            console.log('=================')
            if(err.error){ 
              if(err.error.code == 0){
                let message = err.error.description;
                if(err.status == 500){
                  message = err.message;
                }
                this.snackBar.open(message, '×', {
                    duration: 6000,
                    verticalPosition: 'top'
                });  
                return false;
              } 
            } 
            
            if(err.status == 401 || err.status == 403 ){  //if Unauthorized
              this.snackBar.open(err.error?.error, '×', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'center'
              }); 
              this.dialogRef.closeAll();
              this.router.navigate(['/login']);
              return false;
            }
            if(err.status == 0){  //no connect or bad gateway 502
              this.snackBar.open('Şəbəkə xətası', '×', {
                  duration: 6000,
                  verticalPosition: 'top'
              }); 
              this.dialogRef.closeAll();    
              return false;
            } 
            if(err.status == 500){  //no connect or bad gateway 502
              this.snackBar.open(err.message, '×', {
                  duration: 6000,
                  verticalPosition: 'top'
              }); 
              this.dialogRef.closeAll();    
              return false;
            }
            const started = Date.now();            
            const elapsed = Date.now() - started;
            console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
            console.log('ERROR: => ', err)
           // debugger; 
            if(err){
              // this.snackBar.openFromComponent(ResponseExceptionComponent, {
              //   data: err,
              //   duration: 6000,
              //   verticalPosition: 'top'
              // });
            } 
          }
          return err;
        }))
    }  
}