import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../app.config.service';
import { BaseResponse } from '../models/base-response';
import { Country } from '../models/country';
import { BaseService } from './base.service';
import { CommonService } from './common.service';

export class Data {
  constructor(
    public countries: any[],
    public cities: any[],
    public organizationTypes: any[],
    public organizations: any[],
    public departmentTypes: any[],
    public departments: any[],
    public bankAccountTypes: any[],
    public categories: any[],
    public categoryByType: any[],
    public subCategories: any[],
    public listSubCategoryByCategory: any[],
    public measurementTypes: any[],
    public measurements: any[],
    public stocks: any[],
    public personTypes: any[],
    public genders: any[],
    public departmentPositions: any[],
    public roles: any[],
    public phoneNumberTypes: any[],
    public phonePrefixes: any[],
    public addressTypes: any[],
    public brands: any[],
    public vehicleMakes: any[],
    public vehicleModels: any[],
    public fuelTypes: any[],
    public paymentTypes: any[],
    public paymentStatuses: any[],
    public productTypes: any[],
    public productStatuses: any[],
    public taxCodes: any[],
    public shelfs: any[],
    public returnedReasons: any[],
    public equipmentTypes: any[],
    public webSiteDownloadTypes: any[],
    public availability: any[],
    public productType: any[],
    public mast: any[],
    public driveType: any[],
  ) {}
} 


@Injectable({
  providedIn: 'root',
})
export class ListsService extends BaseService {
  // listsService.data.subCategories
  public data: any = new Data(
    [], // countries
    [], // cities
    [], // organizationTypes
    [], // organizations
    [], // departmentTypes
    [], // departments
    [], // bankAccountTypes
    [], // categories
    [], // categoryByType
    [], // subCategories
    [], // listSubCategoryByCategory
    [], // measurementTypes
    [], // measurements
    [], // stocks
    [], // personTypes
    [], // genders
    [], // departmentPositions
    [], // roles
    [], // phoneNumberTypes
    [], // phonePrefixes
    [], // addressTypes
    [], // brands
    [], // vehicleMakes
    [], // vehicleModels
    [], // fuelTypes
    [], // paymentTypes
    [], // paymentStatuses
    [], // productTypes
    [], // productStatuses
    [], // taxCodes
    [], // shelfs
    [], // returnedReasons
    [], // equipmentTypes
    [], // webSiteDownloadTypes
    [], // availability
    [], // productType
    [], // mast
    [] // driveType
  );

  constructor(
    public environment: AppConfigService,
    public http: HttpClient,
    public translateService: TranslateService,
    public commonService: CommonService,
  ) {
    super(environment, http);
  }

  getVailability() {
    if (!this.data.availability.length) {
      this.get('/product/availability').subscribe((res: BaseResponse) => {
        this.data.availability = res;
      });
    }
  }

  getProductType() {
    if (!this.data.productType.length) {
      this.get('/product/productType').subscribe((res: BaseResponse) => {
        this.data.productType = res;
      });
    }
  }

  getDriveType() {
    if (!this.data.driveType.length) {
      this.get('/product/driveType').subscribe((res: BaseResponse) => {
        this.data.driveType = res;
      });
    }
  }

  getMast() {
    if (!this.data.mast.length) {
      this.get('/product/mast').subscribe((res: BaseResponse) => {
        this.data.mast = res;
      });
    }
  }

  getCountries() {
    if (!this.data.countries.length) {
      this.get(
        '/country/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.countries = res.data;
        }
      });
    }
  }
  getSubGategories() {
    if (!this.data.subCategories.length) {
      this.get(
        '/sub-category/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.subCategories = res.data;
        }
      });
    }
  }

  getCities() {
    if (!this.data.cities.length) {
      this.get(
        '/city/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.cities = res.data;
        }
      });
    }
  }

  getOrganizationTypes() {
    if (!this.data.organizationTypes.length) {
      this.get(
        '/organization-type/listDropDown?lang=' +
          this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.organizationTypes = res.data;
        }
      });
    }
  }

  getOrganizations(type: number = 0) {
    // if(!this.data.organizations.length){
    let urlParams = 'lang=' + this.translateService.currentLang;
    if (type) {
      urlParams = urlParams + '&type=' + type;
    }
    this.get('/organization/listDropDown?' + urlParams).subscribe(
      (res: BaseResponse) => {
        if (res.status) {
          this.data.organizations = res.data;
        }
      }
    );
    //  }
  }

  getDepartmentTypes() {
    if (!this.data.departmentTypes.length) {
      this.get(
        '/department-type/listDropDown?lang=' +
          this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.departmentTypes = res.data;
        }
      });
    }
  }

  getDepartments() {
    // if(!this.data.departments.length){
    this.get(
      '/department/listDropDown?lang=' + this.translateService.currentLang
    ).subscribe((res: BaseResponse) => {
      if (res.status) {
        this.data.departments = res.data;
      }
    });
    // }
  }

  getBankAccountTypes() {
    if (!this.data.bankAccountTypes.length) {
      this.get(
        '/bank-account-type/listDropDown?lang=' +
          this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.bankAccountTypes = res.data;
        }
      });
    }
  }

  getGategories() {
    if (!this.data.categories.length) {
      this.get(
        '/category/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.categories = res.data;
        }
      });
    }
  }

  getGategoriesByType(type: string) {
    // console.log({dataBy: this.data.categoryByType.length});
    
    // if (!this.data.categoryByType.length) {
      console.log("lIST service type", type);
      
      this.get('/category/listCategoryByType?categoryType=' + type).subscribe(
        (res: BaseResponse) => {
          if (res.status) {
            this.data.categoryByType = res.data;
          }
        }
      );
   // }
  }

  getSubGategoriesByCategory(id: string) {
    let subCategoryWithId: any = [];
    // if (!this.data.listSubCategoryByCategory.length) {
      this.get('/sub-category/listSubCategoryByCategoryId?categoryId=' + id).subscribe(
        (res: BaseResponse) => {
          if (res.status) {
            console.log("res.data", res.data);
            
            this.data.listSubCategoryByCategory = res.data;
            subCategoryWithId.push(...res.data);
          }
        }
      );
      console.log({subCategoryWithId});
      
      return subCategoryWithId;
    //}
  }

  getMeasurementTypes() {
    if (!this.data.measurementTypes.length) {
      this.get(
        '/measurement-type/listDropDown?lang=' +
          this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.measurementTypes = res.data;
        }
      });
    }
  }

  getMeasurements() {
    if (!this.data.measurements.length) {
      this.get(
        '/measurement/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.measurements = res.data;
        }
      });
    }
  }

  getStocks() {
    if (!this.data.stocks.length) {
      this.get(
        '/stock/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.stocks = res.data;
        }
      });
    }
  }

  getPersonTypes() {
    if (!this.data.personTypes.length) {
      this.get(
        '/person-type/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.personTypes = res.data;
        }
      });
    }
  }

  getGenders() {
    if (!this.data.genders.length) {
      this.get(
        '/gender/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.genders = res.data;
        }
      });
    }
  }

  getDepartmentPositions() {
    if (!this.data.departmentPositions.length) {
      this.get(
        '/department-position/listDropDown?lang=' +
          this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.departmentPositions = res.data;
        }
      });
    }
  }

  getRoles() {
    if (!this.data.roles.length) {
      this.get(
        '/role/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.roles = res.data;
        }
      });
    }
  }

  getPhoneNumberTypes() {
    if (!this.data.phoneNumberTypes.length) {
      this.get(
        '/phone-number-type/listDropDown?lang=' +
          this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.phoneNumberTypes = res.data;
        }
      });
    }
  }

  getPhonePrefixes() {
    if (!this.data.phonePrefixes.length) {
      this.get(
        '/phone-prefix/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.phonePrefixes = res.data;
        }
      });
    }
  }

  getAddressTypes() {
    if (!this.data.addressTypes.length) {
      this.get(
        '/address-type/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.addressTypes = res.data;
        }
      });
    }
  }

  getBrands() {
    if (!this.data.brands.length) {
      this.get(
        '/brand/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.brands = res.data;
        }
      });
    }
  }

  getVehicleMakes() {
    if (!this.data.vehicleMakes.length) {
      this.get(
        '/vehicle-make/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.vehicleMakes = res.data;
        }
      });
    }
  }

  getVehicleModels() {
    if (!this.data.vehicleModels.length) {
      this.get(
        '/vehicle-model/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.vehicleModels = res.data;
        }
      });
    }
  }

  getFuelTypes() {
    if (!this.data.fuelTypes.length) {
      this.get(
        '/fuel-type/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.fuelTypes = res.data;
        }
      });
    }
  }

  getPaymentTypes() {
    if (!this.data.paymentTypes.length) {
      this.get(
        '/payment-type/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.paymentTypes = res.data;
        }
      });
    }
  }

  getPaymentStatuses() {
    if (!this.data.paymentStatuses.length) {
      this.get(
        '/payment-status/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.paymentStatuses = res.data;
        }
      });
    }
  }

  getProductTypes() {
    if (!this.data.productTypes.length) {
      this.get(
        '/product-type/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.productTypes = res.data;
        }
      });
    }
  }

  getProductStatuses() {
    if (!this.data.productStatuses.length) {
      this.get(
        '/product-status/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.productStatuses = res.data;
        }
      });
    }
  }

  getTaxCodes() {
    if (!this.data.taxCodes.length) {
      this.get(
        '/tax-code/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.taxCodes = res.data;
        }
      });
    }
  }

  getShelfs() {
    if (!this.data.shelfs.length) {
      this.get(
        '/shelf/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.shelfs = res.data;
        }
      });
    }
  }

  getReturnedReasons() {
    if (!this.data.returnedReasons.length) {
      this.get(
        '/returned-reason/listDropDown?lang=' +
          this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.returnedReasons = res.data;
        }
      });
    }
  }

  getEquipmentTypes() {
    if (!this.data.equipmentTypes.length) {
      this.get(
        '/equipment-type/listDropDown?lang=' + this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.equipmentTypes = res.data;
        }
      });
    }
  }

  getWebSiteDownloadTypes() {
    if (!this.data.webSiteDownloadTypes.length) {
      this.get(
        '/web-site-download-type/listDropDown?lang=' +
          this.translateService.currentLang
      ).subscribe((res: BaseResponse) => {
        if (res.status) {
          this.data.webSiteDownloadTypes = res.data;
        }
      });
    }
  }

  // https://api.ce-parts.az/api/person/barrow/300

  getPersonBarrow(id: any) {
    return this.get('/person/barrow/' + id);
  }

  productCount(id: any) {
    return this.get('/stock-income/productCounts/' + id);
  }
}
