import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class OrderGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {      
        if (localStorage.getItem('casingId')) {
            return true;
        } 
    
        this.router.navigate(['/order-operations/cashier']);
        return false;
    }
}