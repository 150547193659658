import { Directive, OnDestroy, ElementRef } from '@angular/core'; 
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
  selector: '[appMaskDateTime]'
})
export class MaskDateTimeDirective implements OnDestroy {

  public mask =  [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/,' ',/\d/, /\d/,':', /\d/, /\d/];  
  public maskedInputController:any;

  constructor(private element: ElementRef) {
    this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      mask: this.mask, 
      guide: true
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }

}