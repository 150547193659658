import { Component, ViewChild, Input, Optional, Self, OnInit, SimpleChange, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NgControl, FormControlName, FormControl, Validators } from '@angular/forms'; 
import { MatFormFieldControl } from '@angular/material/form-field';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseResponse } from '../../models/base-response';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styleUrls: ['./custom-autocomplete.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CustomAutocompleteComponent,
    },
  ],
})
export class CustomAutocompleteComponent
  implements ControlValueAccessor, OnInit
{
  @ViewChild('field', { static: true }) field: any;
  @Input('label') label: string = '';
  @Input('url') url = '';
  @Input('errors') errors = [];
  @Input('validatedPropertyName') validatedPropertyName: string = '';
  @Input('isDisable') isDisable: boolean = false;
  @Input('dependentColumnId') dependentColumnId: any;
  @Input('extraClass') extraClass: string = '';
  @Input('typeIds') typeIds: any;
  @Input('property') property: string = 'name';
  @Input('showpartnumber') showpartnumber: boolean = false;
  @Input('showcountryname') showcountryname: boolean = false;

  @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter();
  public searchText: string = '';
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public complete: boolean = false;
  public list: any = [];
  public control: FormControl = new FormControl();
  public noResults: boolean = false;

  @Input()
  get value() {
    return this.field.value;
  }
  set value(val) {
    this.field.value = val;
  }

  constructor(
    @Optional() @Self() ngControl: NgControl,
    @Optional() private _controlName: FormControlName,
    public commonService: CommonService
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
    // console.log('Admin');
  }

  ngOnInit(): void {
    this.control = this._controlName.control;
    this.isDisable ? this.control.disable() : this.control.enable();
    this.control.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val) => {
        console.log({ cusVal: val });
        if (val) {
          this.complete = false;
          this.searchText = val;
          this.list = [];
          if (val.length >= 3) {
            this.getList();
          }
        } else {
          this.onSelectionChanged.emit(null);
        }
      });
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    // console.log(changes,"changes")
    // if(!changes.isDisable?.isFirstChange()){
    //   if(changes.dependentColumnId){
    //     if(changes.dependentColumnId?.currentValue && !changes.isDisable?.currentValue){
    //       this.control?.enable()
    //     }else{
    //       this.control?.disable();
    //       this.control?.reset();
    //     }
    //   }
    // }
    // if(changes.errors){
    //   if(changes.errors){
    //     if(!changes.errors.isFirstChange()){
    //       this.errors = changes.errors.currentValue;
    //       let error = this.errors.find((err:any) => err.Field.toLowerCase().split(" ")[0] == this.validatedPropertyName.toLowerCase());
    //       if(error){
    //         this.control.setValidators([Validators.required]);
    //         this.control.updateValueAndValidity();
    //         this.control.markAsTouched();
    //       }
    //     }
    //   }
    // }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  public onScroll() {
    if (!this.complete) {
      this.pageIndex++;
      this.getList();
    }
  }

  public getList() {
    let params: { [k: string]: any } = {
      [this.property]: this.searchText,
      skip: this.pageIndex * this.pageSize,
      limit: this.pageSize,
    };
    if (this.typeIds) {
      params.typeIds = this.typeIds;
    }
    // if(this.dependentColumnId){
    //   params['dependentColumnId'] = this.dependentColumnId;
    // };
    this.commonService
      .getByParams(this.url, params)
      .subscribe((res: BaseResponse) => {
        if (res.status) {
          !res.data.list.length
            ? (this.noResults = true)
            : (this.noResults = false);
          this.list.push(...res.data.list);
          console.log('el console', res.data.list);
          if (res.data.list.length < this.pageSize) {
            this.complete = true;
          }
        }
      });
  }

  public onOptionSelected(event: any) {
    this.onSelectionChanged.emit(event.option.value);
  }

  public onBlur() {
    if (!this.control.value?.id) {
      this.control.patchValue(null);
    }
  }

  public displayFn(result: any) {
   // console.log({ culResult: result });

    return result ? result.name : undefined;
  }
}
