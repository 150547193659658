import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/common.service';

@Pipe({
  name: 'getUrl'
})
export class GetUrlPipe implements PipeTransform {

  constructor(public commonService: CommonService) { }

  transform(value: any, ...args: unknown[]): unknown {
    return this.generateUrl(value); 
  }

  generateUrl(path:string){  
    return decodeURIComponent(this.commonService.getBaseUrl() + path);
  }

}
