import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-discount-counter',
  templateUrl: './discount-counter.component.html',
  styleUrls: ['./discount-counter.component.scss']
})
export class DiscountCounterComponent implements OnInit {
  @Input() value:any = 0;
  @Output() onCounterChange: EventEmitter<any> = new EventEmitter<any>(); 
  constructor() { }

  ngOnInit(): void {
    this.value = (this.value) ? this.value : 0;
  }

  onValueChange(value:any){ 
    this.value = value ? value : 0;
    this.onCounterChange.emit(this.value); 
  }

}
