<mat-form-field appearance="outline" class="w-100 sm">
    <mat-label>{{ label | translate}}</mat-label> 
    <mat-chip-list #chipList [formControl]="control">
        <mat-chip
            *ngFor="let item of items"
            [selectable]="true"
            [removable]="true"
            (removed)="remove(item)">
            {{item.name}}
            <mat-icon matChipRemove [matTooltip]="'btn.delete' | translate">cancel</mat-icon>
        </mat-chip>
        <input 
            [placeholder]="'field.search' | translate"
            #field  
            matInput
            [ngModel]="searchText" (ngModelChange)="onChangeInput($event)"          
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" (optionsScroll)="onScroll()" class="custom-autocomplete">
        <mat-option *ngFor="let item of allItems" [value]="item">
            {{item.name}}
        </mat-option>
        <mat-option *ngIf="!items.length && noResults" value="" disabled="true">
            <b>No results found</b>
        </mat-option> 
    </mat-autocomplete>
</mat-form-field>