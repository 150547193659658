<div mat-dialog-title> 
    <div class="text-center"> 
        <h4 class="mb-1">{{title}}</h4>
        <mat-icon class="mat-icon-xlg" color="primary">warning</mat-icon>
    </div> 
</div> 
<div mat-dialog-content>
    <p class="mb-3">{{message}}</p>
</div> 
<div mat-dialog-actions fxLayoutAlign="space-between center" class="pb-4">
    <button mat-raised-button color="warn" (click)="onDismiss()">{{ 'btn.no' | translate }}</button>
    <button mat-raised-button color="accent" (click)="onConfirm()">{{ 'btn.yes' | translate }}</button>
</div>