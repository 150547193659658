import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../shared/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';  

@Injectable({
  providedIn: 'root'
})

export class UtilsService { 
  constructor(public dialog: MatDialog, public translateService:TranslateService) { }

  public openDialog(component: any, data:any, panelClass:string[] = ['amet-dialog'], minWidth:number = 320, maxWidth:number = 480) {
    const dialogRef = this.dialog.open(component, { 
      data: data,
      panelClass: panelClass,
      autoFocus: false,
      disableClose: true, 
      minWidth: minWidth,
      maxWidth: maxWidth
    }); 
    return dialogRef; 
  }

  public openConfirmDialog(title:string, message:string) {  
    const dialogData = new ConfirmDialogModel(title, message); 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      autoFocus: false
    }); 
    return dialogRef; 
  }

  public openAlertDialog(message:string) {   
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: message,
      autoFocus: false
    }); 
    return dialogRef; 
  }

  public getTranslateValue(key:string, param:string = ''){  
    let value = null;
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;
    }) 
    return value; 
  } 

  public clientSideSorting(arr:any[], sort:any){
    if (sort.direction === '') {
      arr.sort((a, b) => (a.id > b.id) ? 1 : -1);
      return;
    } 
    arr.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active){
        return this.compare(a[sort.active], b[sort.active], isAsc);
      }
      return 0; 
    });
  }
  
  private compare(a:string | number, b:string | number, isAsc:boolean = true) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  public stringToDate(str:string){
    let val = null;
    if(str){ 
      val = moment(str.replace(/(\d{2}).(\d{2}).(\d{4})/, "$3-$2-$1")).format();  
    }
    return val;
  }

  public dateToString(date:any){
    let val = null;
    if(date?.toString()?.includes('.')){
      return date;
    }
    if(date){ 
      val = moment(new Date(date)).format('DD.MM.YYYY HH:mm:ss'); 
    }
    return val;
  }

  public dateToStringForReports(date:any){
    var dt;
    if(date?.toString()?.includes('.')){
      return date;
    }
    dt = moment(date ?? new Date()).format('DD.MM.YYYY');    
    return dt; 
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public findInvalidControls(form:any) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
      if(controls[name].controls){ 
        for (const child in controls[name].controls) {
          if (controls[name].controls[child].invalid) {
            invalid.push(child);
          }
        }
      }
    }
    console.log('invalid fields: ', invalid)
    return invalid;
  }
  
  public scrollToError(){
    const firstElementWithError = document.querySelector('.mat-form-field.ng-invalid'); 
    if (firstElementWithError) { 
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });  
    }
  }

  public getGUID(){
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    }).toLowerCase();
    return guid;
  } 

  public formatDate(date: any = new Date()){
    let val = date?.toLocaleDateString("en-US")?.split('/');
    return `${[val[1]]}.${val[0]}.${val[2]}`
  }
  
} 


