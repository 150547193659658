<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500">{{ (data ? 'btn.edit' : 'btn.add') | translate }}</h2>
        <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
    </div>   
</div>  

<div mat-dialog-content> 

    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off" class="mt-4">  

        <mat-accordion multi class="amet-accordion" hideToggle="false"> 
            
            <mat-expansion-panel [expanded]="true" class="pb-0" [disabled]="false">
                <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
                    <mat-panel-title>Şəxsi məlumatlar</mat-panel-title> 
                </mat-expansion-panel-header>
                
                <div formGroupName="person" fxLayout="row wrap" class="flex-wrapper-x">
                    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-2">
                        <mat-form-field appearance="outline" class="w-100 sm"> 
                            <mat-label>{{ 'field.name' | translate }}</mat-label>
                            <input matInput formControlName="name" required/>  
                            <mat-error *ngIf="$any(form.get('person'))['controls'].name.errors?.required">{{ 'validation.required' | translate }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-2">
                        <mat-form-field appearance="outline" class="w-100 sm"> 
                            <mat-label>{{ 'field.surname' | translate }}</mat-label>
                            <input matInput formControlName="surname"/> 
                            <mat-error *ngIf="$any(form.get('person'))['controls'].surname.errors?.required">{{ 'validation.required' | translate }}</mat-error>
                        </mat-form-field>
                    </div> 
                    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-2">
                        <mat-form-field appearance="outline" class="w-100 sm"> 
                            <mat-label>{{ 'field.patronymic' | translate }}</mat-label>
                            <input matInput formControlName="patronymic"/> 
                            <mat-error *ngIf="$any(form.get('person'))['controls'].patronymic.errors?.required">{{ 'validation.required' | translate }}</mat-error>
                        </mat-form-field>
                    </div> 

                    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-2">
                        <mat-form-field appearance="outline" class="w-100 sm"> 
                            <mat-label>{{ 'field.gender' | translate }}</mat-label> 
                            <mat-select formControlName="genderId"> 
                                <mat-option [value]="">-</mat-option>
                                <mat-option *ngFor="let item of listsService.data.genders" [value]="item.id">
                                    {{item.name}}
                                </mat-option>  
                            </mat-select> 
                            <mat-error *ngIf="$any(form.get('person'))['controls'].genderId.errors?.required">{{ 'validation.required' | translate }}</mat-error>
                        </mat-form-field> 
                    </div> 
                    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-2">
                        <mat-form-field appearance="outline" class="w-100 sm"> 
                            <mat-label>{{ 'field.personType' | translate }}</mat-label> 
                            <mat-select formControlName="personTypeId"> 
                                <mat-option [value]="">-</mat-option>
                                <mat-option *ngFor="let item of listsService.data.personTypes" [value]="item.id">
                                    {{item.name}}
                                </mat-option>  
                            </mat-select> 
                            <mat-error *ngIf="$any(form.get('person'))['controls'].personTypeId.errors?.required">{{ 'validation.required' | translate }}</mat-error>
                        </mat-form-field> 
                    </div> 

                    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-2">
                        <app-custom-autocomplete [label]="'field.organization'" 
                                        formControlName="organizationName" 
                                        url="/organization/listDropDown"
                                        (onSelectionChanged)="organizationSelected($event)"
                                        validatedPropertyName="organizationId">
                        </app-custom-autocomplete> 
                    </div> 

                </div> 

                 
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" class="pb-0" [disabled]="false">
                <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <span>{{ 'field.contact-numbers' | translate }}</span>
                        <button mat-icon-button color="accent" type="button" (click)="addPhoneNumber(); utilsService.stopClickPropagate($event)" [matTooltip]="'btn.add' | translate" matTooltipPosition="after">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </mat-panel-title> 
                </mat-expansion-panel-header> 

                <div formArrayName="personPhoneNumbers" fxLayout="row wrap" class="flex-wrapper-x">  
                    <div *ngFor="let item of $any(form.get('personPhoneNumbers')).controls; let i = index;" fxFlex="100" class="step-section">
                        <div fxFlex="4" class="fw-500 text-center pt-2">{{i+1}}.</div>
                        <div [formGroupName]="i" fxFlex="90">
                            <div fxLayout="row wrap"> 
                                <div fxFlex="100" fxFlex.gt-sm="25" class="px-2"> 
                                    <mat-form-field appearance="outline" class="w-100 sm"> 
                                        <mat-label>{{ 'field.phoneNumberType' | translate }}</mat-label> 
                                        <mat-select formControlName="phoneNumberTypeId" required> 
                                            <mat-option [value]="">-</mat-option>
                                            <mat-option *ngFor="let type of listsService.data.phoneNumberTypes" [value]="type.id">
                                                {{type.name}}
                                            </mat-option>  
                                        </mat-select> 
                                        <mat-error *ngIf="$any(form.get('personPhoneNumbers')).controls[i].controls.phoneNumberTypeId.errors?.required">{{ 'validation.required' | translate }}</mat-error>  
                                    </mat-form-field> 
                                </div>  
                                <div fxFlex="100" fxFlex.gt-sm="20" class="px-2">
                                    <mat-form-field appearance="outline" class="w-100 sm"> 
                                        <mat-label>{{ 'field.phonePrefix' | translate }}</mat-label> 
                                        <mat-select formControlName="phonePrefixId" required> 
                                            <mat-option [value]="">-</mat-option>
                                            <mat-option *ngFor="let item of listsService.data.phonePrefixes" [value]="item.id">
                                                {{item.name}}
                                            </mat-option>  
                                        </mat-select> 
                                        <mat-error *ngIf="$any(form.get('personPhoneNumbers')).controls[i].controls.phonePrefixId.errors?.required">{{ 'validation.required' | translate }}</mat-error>  
                                    </mat-form-field> 
                                </div> 
                                <div fxFlex="100" fxFlex.gt-sm="30" class="px-2">
                                    <mat-form-field appearance="outline" class="w-100 sm"> 
                                        <mat-label>{{ 'field.number' | translate }}</mat-label>
                                        <input matInput formControlName="phoneNumber" required/> 
                                        <mat-error *ngIf="$any(form.get('personPhoneNumbers')).controls[i].controls.phoneNumber.errors?.required">{{ 'validation.required' | translate }}</mat-error> 
                                    </mat-form-field> 
                                </div> 
                                <div fxFlex="100" fxFlex.gt-sm="25" class="px-2 pt-2">
                                    <mat-checkbox formControlName="confirmed" color="primary">{{ 'field.confirmed' | translate }} </mat-checkbox>  
                                </div>   
                            </div>
                        </div>
                        <div fxFlex="6" class="text-center">
                            <button mat-icon-button color="warn" type="button" (click)="deletePhoneNumber(i)" [matTooltip]="'btn.delete' | translate" matTooltipPosition="above">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>                            
                    </div>        
                </div> 
                
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" class="pb-0" [disabled]="false">
                <mat-expansion-panel-header collapsedHeight="36px" expandedHeight="36px">
                    <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                        <span>{{ 'field.addresses' | translate }}</span>
                        <button mat-icon-button color="accent" type="button" (click)="addAddress(); utilsService.stopClickPropagate($event)" [matTooltip]="'btn.add' | translate" matTooltipPosition="after">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </mat-panel-title> 
                </mat-expansion-panel-header> 

                <div formArrayName="personAddresses" fxLayout="row wrap" class="flex-wrapper-x">  
                    <div *ngFor="let item of $any(form.get('personAddresses')).controls; let i = index;" fxFlex="100" class="step-section">
                        <div fxFlex="4" class="fw-500 text-center pt-2">{{i+1}}.</div>
                        <div [formGroupName]="i" fxFlex="90">
                            <div fxLayout="row wrap">  
                                <div fxFlex="100" fxFlex.gt-sm="25" class="px-2"> 
                                    <mat-form-field appearance="outline" class="w-100 sm"> 
                                        <mat-label>{{ 'field.city' | translate }}</mat-label> 
                                        <mat-select formControlName="cityId" required>  
                                            <mat-option [value]="">-</mat-option>
                                            <mat-option *ngFor="let item of listsService.data.cities" [value]="item.id">
                                                {{item.name}}
                                            </mat-option>  
                                        </mat-select> 
                                        <mat-error *ngIf="$any(form.get('personAddresses')).controls[i].controls.cityId.errors?.required">{{ 'validation.required' | translate }}</mat-error>  
                                    </mat-form-field> 
                                </div>  
                                <div fxFlex="100" fxFlex.gt-sm="25" class="px-2">
                                    <mat-form-field appearance="outline" class="w-100 sm"> 
                                        <mat-label>{{ 'field.addressType' | translate }}</mat-label> 
                                        <mat-select formControlName="addressTypeId" required> 
                                            <mat-option [value]="">-</mat-option>
                                            <mat-option *ngFor="let type of listsService.data.addressTypes" [value]="type.id">
                                                {{type.name}}
                                            </mat-option>  
                                        </mat-select> 
                                        <mat-error *ngIf="$any(form.get('personAddresses')).controls[i].controls.addressTypeId.errors?.required">{{ 'validation.required' | translate }}</mat-error>  
                                    </mat-form-field> 
                                </div> 
                                <div fxFlex="100" fxFlex.gt-sm="50" class="px-2">
                                    <mat-form-field appearance="outline" class="w-100 sm"> 
                                        <mat-label>{{ 'field.address' | translate }}</mat-label>
                                        <input matInput formControlName="address" required/> 
                                        <mat-error *ngIf="$any(form.get('personAddresses')).controls[i].controls.address.errors?.required">{{ 'validation.required' | translate }}</mat-error> 
                                    </mat-form-field> 
                                </div>  
                            </div>
                        </div>
                        <div fxFlex="6" class="text-center">
                            <button mat-icon-button color="warn" type="button" (click)="deleteAddress(i)" [matTooltip]="'btn.delete' | translate" matTooltipPosition="above">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>                            
                    </div>        
                </div>  
                
            </mat-expansion-panel>

        </mat-accordion>  

        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 pb-1 pt-3"> 
            <button mat-raised-button color="warn" type="button" mat-dialog-close>{{ 'btn.cancel' | translate }}</button>
            <button mat-raised-button color="accent" type="submit">{{ 'btn.save' | translate }}</button>
        </div> 
    </form>

</div> 