import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config.service';
import { BaseResponse } from '../models/base-response';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(public environment: AppConfigService, 
              public http:HttpClient ) { }

  public getBaseUrl(){
    return this.environment.config.url;
  }

  public get(path:string): Observable<BaseResponse>{
    return this.http.get<BaseResponse>(this.getBaseUrl() + path); 
  }

  public getById(path:string): Observable<BaseResponse>{
    return this.http.get<BaseResponse>(this.getBaseUrl() + path);
  }

  public post(path:string, object:any): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(this.getBaseUrl() + path, object);
  }

  public put(path:string, object:any): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(this.getBaseUrl() + path, object);
  }  

  public getByParams(path:string, params:any): Observable<BaseResponse>{ 
    return this.http.get<BaseResponse>(this.getBaseUrl() + path,  { params: params } ); 
  }  

  public deleteMethodById(path:string, id:any){
    return this.http.delete(`${this.getBaseUrl()}/${path}/${id}`);
  }

}
