import { Injectable } from '@angular/core';

export class Settings {
    constructor(public name: string, 
                public sidenavIsOpened: boolean,
                public disableBtn: boolean
                ) { }
}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        'CE-PARTS',      //  theme name 
        true,        //  sidenavIsOpened 
        false        //  disableBtn
    )
}