import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseResponse } from 'projects/admin/src/app/models/base-response';
import { ListsService } from 'projects/admin/src/app/services/lists.service';
import { CommonService } from '../../services/common.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-person-dialog',
  templateUrl: './person-dialog.component.html'
})
export class PersonDialogComponent implements OnInit {
  public form: FormGroup = new FormGroup({});
  public departments:any[] = [];
  public positions:any[] = []; 
  constructor(public dialogRef: MatDialogRef<PersonDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: FormBuilder,
              public commonService: CommonService,
              public listsService: ListsService,
              public utilsService: UtilsService) { }

  ngOnInit(): void {
    this.listsService.getPersonTypes();
    this.listsService.getGenders();
    this.listsService.getOrganizations();  
    this.listsService.getPhoneNumberTypes();
    this.listsService.getPhonePrefixes();
    this.listsService.getAddressTypes();
    this.listsService.getCities();
    this.form = this.fb.group({ 
      person: this.fb.group({
        id: null,
        name: [null, Validators.required],
        surname: null,
        patronymic: null,
        genderId: [null, Validators.required],
        personTypeId: [null, Validators.required],
        organizationId: [null, Validators.required],
        organizationName: [null, Validators.required]
      }), 
      personPhoneNumbers: this.fb.array([]),
      personAddresses: this.fb.array([])
    }); 
    if(this.data){
      this.form.patchValue(this.data);  

      const personPhoneNumbers = this.form.get('personPhoneNumbers') as FormArray;
      this.data.personPhoneNumbers.forEach((phoneNumber:any) => { 
        personPhoneNumbers.push(this.fb.group(phoneNumber))
      }); 
       
      const personAddresses = this.form.get('personAddresses') as FormArray;
      this.data.personAddresses.forEach((personAddress:any) => { 
        personAddresses.push(this.fb.group(personAddress))
      }); 

      (this.form.get('person') as FormGroup).controls.organizationName.patchValue({
        id: this.data.person.organizationId,
        name: this.data.person.organizationName
      });  
    }
  }

  public createPhoneNumber(): FormGroup {
    return this.fb.group({
      id: null,
      phoneNumber: [null, Validators.required],
      phonePrefixId: [null, Validators.required], 
      phoneNumberTypeId: [null, Validators.required],
      confirmed: false
    });
  }
  public addPhoneNumber(): void {  
    const phoneNumbers = this.form.get('personPhoneNumbers') as FormArray; 
    phoneNumbers.push(this.createPhoneNumber());
  }
  public deletePhoneNumber(index:number) {
    const phoneNumbers = this.form.get('personPhoneNumbers') as FormArray;
    phoneNumbers.removeAt(index);
  } 

  public createAddress(): FormGroup {
    return this.fb.group({
      id: null,
      address: [null, Validators.required],
      addressTypeId: [null, Validators.required],
      cityId: [null, Validators.required]
    });
  }
  public addAddress(): void { 
    const addresses = this.form.get('personAddresses') as FormArray;
    addresses.push(this.createAddress());
  }
  public deleteAddress(index:number) {
    const addresses = this.form.get('personAddresses') as FormArray;
    addresses.removeAt(index);
  } 

  public onSubmit(){ 
    let data = this.form.value;  
    data.person.organizationName = null; 
    console.log(data)
    if(this.form.valid){
      if(data.person.id){
        this.commonService.edit('person', data).subscribe((res:BaseResponse) => {
          if(res.status) 
            this.dialogRef.close(true);
        })
      }
      else{
        this.commonService.add('person', data).subscribe((res:BaseResponse) => {
          if(res.status) 
            this.dialogRef.close(true);
        })
      } 
    }
  } 

  public organizationSelected(obj:any){ 
    let id = (obj) ? obj.id : null;
    (this.form.get('person') as FormGroup).controls.organizationId.patchValue(id);  
  } 

}
