import { Component, ViewChild, Input, ChangeDetectionStrategy, Optional, Self, DoCheck, OnInit, SimpleChange, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NgControl, FormControlName, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select'; 

@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.scss'], 
    providers: [
      {
        provide: MatFormFieldControl,
        useExisting: SelectSearchComponent
      }
    ] 
})
export class SelectSearchComponent implements ControlValueAccessor, OnInit { 
    @ViewChild('select', { static: true }) select!: MatSelect;
    @Input('label') label:string = '';
    @Input('list') list:any[] = [];
    @Input('property') property:string = 'name';
    @Input('isDisable') isDisable:boolean = false;
    @Input('extraClass') extraClass:string = '';
    @Input('hasSpinner') hasSpinner:boolean = false;
    @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter(); 
    @Output() onOpenedChanged: EventEmitter<any> = new EventEmitter(); 
    selectedList:any[] = [];   
    control!: FormControl; 

    @Input()
    get errorStateMatcher(): ErrorStateMatcher {
        return this.select.errorStateMatcher;
    }
    set errorStateMatcher(val) {
        this.select.errorStateMatcher = val;
    }

    @Input()
    get placeholder() {
        return this.select.placeholder;
    }
    set placeholder(plh) {
        this.select.placeholder = plh;
    }

    @Input()
    get value() {
        return this.select.value;
    }
    set value(val) {
        this.select.value = val;
    }
 

    constructor(@Optional() @Self() ngControl: NgControl, @Optional() private _controlName: FormControlName) {
      if (ngControl) {
        ngControl.valueAccessor = this;
      }
    }

    ngOnInit(): void {
      this.control = this._controlName.control;
      if(this.isDisable){
        this.setDisabledState(true);
      }
    }

    // ngDoCheck(): void {
    //   this.select.updateErrorState();
    // }

    ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
      this.selectedList = this.list; 
    } 

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.select.registerOnChange(fn);
    }
    registerOnTouched(fn: any): void {
        this.select.registerOnTouched(fn);
    }
    setDisabledState(isDisabled: boolean) : void {
        this.select.setDisabledState(isDisabled);
    }

    public searchList(target:any){
      if(target.value){
        let filter = target.value.toLocaleLowerCase('az-AZ');  
        this.selectedList = this.list.filter((item:any) => item[this.property].toLocaleLowerCase('az-AZ').includes(filter));   
      }
      else{
        this.selectedList = this.list;
      }     
    } 
    public selectionChanged(event: any) {   
      this.selectedList = this.list;
      (<HTMLInputElement>document.getElementById('search-input')).value = "";
      this.onSelectionChanged.emit(event);
    } 
    public openedChanged(event:any){ 
      console.log('opened changed: ' , event)
      if(event){
        this.selectedList = this.list;
        (<HTMLInputElement>document.getElementById('search-input')).value = "";
        (<HTMLInputElement>document.getElementById('search-input')).focus();
        this.onOpenedChanged.emit(this.list);
      }
    }

}