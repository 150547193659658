import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from '../app.config.service';
import { BaseResponse } from '../models/base-response';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService extends BaseService {
  public list = new BehaviorSubject<any>(null);
  public copyList = new BehaviorSubject<any>(null);
  public listCount = new BehaviorSubject<number>(0);
  public copyListCount = new BehaviorSubject<number>(0);
  public alternativeGroups = new BehaviorSubject<any>(null);
  public alternativeGroupsCount = new BehaviorSubject<number>(0);

  constructor(public environment: AppConfigService, public http: HttpClient) {
    super(environment, http);
    // console.log({list: this.list, copyList: this.copyList});
    
  }

  getList(path: string, type?: string, id?: string) {
    console.log("COMMON service type", type);
    
     if (type && id) {
       this.get('/' + path + `/${type}` + '?categoryId=' + id).subscribe(
         (res: BaseResponse) => {
           if (res.status) {
             this.list.next(res.data);
           }
         }
       );
       return;
     }

    if (type) {
      this.get('/' + path + '/list' + '?categoryType=' + type).subscribe(
        (res: BaseResponse) => {
          if (res.status) {
            this.list.next(res.data);
          }
        }
      );
      return;
    }
     
    this.get('/' + path + '/list').subscribe((res: BaseResponse) => {
      if (res.status) {
        this.list.next(res.data);
      }
    });
  }

  getCopyList(path: string, type?: string, id?: string) {
    console.log("COMMON service type", type);
    
     if (type && id) {
       this.get('/' + path + `/${type}` + '?categoryId=' + id).subscribe(
         (res: BaseResponse) => {
           if (res.status) {
             this.copyList.next(res.data);
           }
         }
       );
       return;
     }

    if (type) {
      this.get('/' + path + '/list' + '?categoryType=' + type).subscribe(
        (res: BaseResponse) => {
          if (res.status) {
            this.copyList.next(res.data);
          }
        }
      );
      return;
    }
     
    this.get('/' + path + '/list').subscribe((res: BaseResponse) => {
      if (res.status) {
        this.copyList.next(res.data);
      }
    });
  }

  // getCustomList(path: string, type?: string, id: string) {
  //   if (type) {
  //     this.get('/' + path + '/list' + '?categoryType=' + id).subscribe(
  //       (res: BaseResponse) => {
  //         if (res.status) {
  //           this.list.next(res.data);
  //         }
  //       }
  //     );
  //     return;
  //   }
  //   this.get('/' + path + '/list').subscribe((res: BaseResponse) => {
  //     if (res.status) {
  //       this.list.next(res.data);
  //     }
  //   });
  // }

  add(path: string, data: any) {
    return this.post('/' + path + '/add', data);
  }

  edit(path: string, data: any) {
    return this.post('/' + path + '/edit', data);
  }

  delete(path: string, id: number) {
    return this.post('/' + path + '/delete?id=' + id, null);
  }

  deleteById(path: string, id: number) {
    return this.post('/' + path + '/' + id, null);
  }

  info(path: string, id: number) {
    return this.get('/' + path + '/info?id=' + id);
  }

  getListByParams(path: string, data: any) {
    let params = new HttpParams();
    Object.keys(data).forEach((key, index) => {
      if (data[key] !== null && data[key] !== '' && data[key] !== undefined) {
        params = params.append(key, data[key]);
      }
    });
   console.log('params==', params);
    this.getByParams('/' + path + '/list', params).subscribe(
      (res: BaseResponse) => {
        console.log('res.data.list==', res.data.list);
        if (res.status) {
          switch (path) {
            case 'alternative-group':
              this.alternativeGroups.next(res.data.list);
              this.alternativeGroupsCount.next(res.data.count);
              break;
            default:
              this.list.next(res.data.list);
              this.listCount.next(res.data.count);
              break;
          }
        }
      }
    );
  }

  getCopyListByParams(path: string, data: any) {
    let params = new HttpParams();
    Object.keys(data).forEach((key, index) => {
      if (data[key] !== null && data[key] !== '' && data[key] !== undefined) {
        params = params.append(key, data[key]);
      }
    });
   console.log('params copy==', params);
    this.getByParams('/' + path + '/list', params).subscribe(
      (res: BaseResponse) => {
        console.log('res.data.copylist==', res.data.list);
        if (res.status) {
          switch (path) {
            case 'alternative-group':
              this.alternativeGroups.next(res.data.list);
              this.alternativeGroupsCount.next(res.data.count);
              break;
            default:
              this.copyList.next(res.data.list);
              this.copyListCount.next(res.data.count);
              break;
          }
        }
      }
    );
  }

  resetList(path?: string) {
    switch (path) {
      case 'alternative-group':
        this.alternativeGroups.next(null);
        this.alternativeGroupsCount.next(0);
        break;
      default:
        this.list.next(null);
        this.copyList.next(null);
        this.listCount.next(0);
        this.copyListCount.next(0);
        break;
    }
  }

  getReserve(path: string) {
    this.get('/' + path).subscribe((res: BaseResponse) => {
      if (res.status) {
        this.list.next(res.data);
      }
    });
  }
}
