import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as moment from 'moment'; 
moment.fn.toJSON = function() { return this.format(); }

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [ 
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CustomDateAdapterModule { }


// import { CommonModule } from '@angular/common';
// import { NgModule, ModuleWithProviders } from '@angular/core'; 
// import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
// import { Platform } from '@angular/cdk/platform';

// import { MomentDateAdapter } from '@angular/material-moment-adapter';
// import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
// export const MY_FORMATS : MatDateFormats = {
//   parse: {
//     dateInput: 'DD/MM/YYYY',
//   },
//   display: {
//     dateInput: 'DD.MM.YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

// @NgModule({
//   imports: [
//     CommonModule,
//     MatDatepickerModule,
//     MatNativeDateModule
//   ],
//   exports: [
//     MatDatepickerModule,
//     MatNativeDateModule
//   ]
// })
// export class CustomDateAdapterModule {
//   static forRoot(): ModuleWithProviders {
//     return {
//       ngModule: CustomDateAdapterModule,
//       providers: [ 
//         { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
//         { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
//       ]
//     };
//   }
// }